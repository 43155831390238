import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import { graphql, useStaticQuery } from 'gatsby';

import PitchSlides from '../templates/PitchSlides';
import React from 'react';
import SEO from '../components/Seo';

const slideQuery = graphql`
  query {
    allMatchSlidesJson {
      edges {
        node {
          id
          title
          description
          button {
            text
            link
          }
          bgImage {
            publicURL
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const { allMatchSlidesJson } = useStaticQuery(slideQuery);
  const nodes = allMatchSlidesJson.edges.map(({ node }) => {
    return node;
  });

  return (
    <div className="landing">
      <SEO title="Customize Your Life" />
      <PitchSlides data={nodes} />
    </div>
  );
};

export default IndexPage;
