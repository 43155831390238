import './PitchSlides.scss';

import { Button, Carousel } from 'react-bootstrap';

import PropTypes from 'prop-types';
import React from 'react';
import { navigate } from 'gatsby';

// convert to gatsby background image instead?
const PitchSlides = ({ data }) => {
  const slides = data.map((node) => {
    const pitchBtn = node.button.text ? (
      <Button
        variant="primary"
        size="lg"
        onClick={() => navigate(node.button.link)}
      >
        {node.button.text}
      </Button>
    ) : null;

    return (
      <Carousel.Item key={node.id}>
        <div
          className="pitch-slide"
          style={{
            backgroundImage: `url(${node.bgImage.publicURL})`,
          }}
        >

          <div className="slide-caption">
            <h1>{node.title}</h1>
            <p>{node.description}</p>
            {pitchBtn}
          </div>
        </div>
      </Carousel.Item>
    );
  });

  return (
    <Carousel controls={false} interval={5000}>
      {slides}
    </Carousel>
  );
};

export default PitchSlides;
